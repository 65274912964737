<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-tabs content-class="mt-3">
      <b-tab active title="My Slider">
        <div class="row" v-if="!loading">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <button
                  @click="removeProject"
                  style="float: right"
                  class="btn btn-danger"
                >
                  Remove Selected
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div>
                  <b-table
                    :items="allProjects"
                    :fields="fields"
                    responsive="sm"
                    ref="selectableTableSlider"
                    selectable
                    @row-selected="onRowSelected"
                  >
                    <template #cell(image)="data">
                      <img
                        v-if="data.value"
                        style="width: 100px; height: 100px"
                        :src="data.value"
                      />
                      <img
                        v-else
                        style="width: 100px; height: 100px"
                        src="@/assets/images/error-img.png"
                      />
                    </template>

                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(selected)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true">
                          <input type="checkbox" checked />
                        </span>
                      </template>
                      <template v-else>
                        <input type="checkbox" />
                      </template>
                    </template>
                  </b-table>
                  <p>
                    <b-button
                      size="sm"
                      @click="selectAllRows('selectableTableSlider')"
                      >Select all</b-button
                    >

                    <b-button
                      size="sm"
                      @click="clearSelected('selectableTableSlider')"
                      >Clear selected</b-button
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-12"
            style="
              height: 60vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <pulse-loader color="#505d69" :loading="true"></pulse-loader>
          </div>
        </div>
      </b-tab>
      <b-tab title="Companies">
        <div class="row" v-if="!loading">
          <div class="col-12">
            <button
              @click="addProject"
              style="float: right"
              class="btn btn-success"
            >
              Add Company
            </button>
            <div id="search">
              <input
                type="searchField"
                v-model="searchFieldCompany"
                placeholder="Search Companies.."
              />
            </div>

            <div>
              <b-table
                :items="filteredListCompanies"
                :fields="fieldsProjects"
                responsive="sm"
                ref="selectableTableCompany"
                selectable
                @row-selected="onRowSelected"
              >
                <template #cell(image)="data">
                  <img
                    v-if="data.value"
                    style="width: 100px; height: 100px"
                    :src="data.value"
                  />
                  <img
                    v-else
                    style="width: 100px; height: 100px"
                    src="@/assets/images/error-img.png"
                  />
                </template>
                <!-- <template #cell(action)>
                  <i @click="console.log('clicked')" class="fas fa-trash"></i>
                </template> -->

                <!-- Example scoped slot for select state illustrative purposes -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">
                      <input type="checkbox" checked />
                    </span>
                  </template>
                  <template v-else>
                    <input type="checkbox" />
                  </template>
                </template>
              </b-table>
              <p>
                <b-button
                  size="sm"
                  @click="selectAllRows('selectableTableCompany')"
                  >Select all</b-button
                >

                <b-button
                  size="sm"
                  @click="clearSelected('selectableTableCompany')"
                  >Clear selected</b-button
                >
              </p>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-12"
            style="
              height: 60vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <pulse-loader color="#505d69" :loading="true"></pulse-loader>
          </div>
        </div>
      </b-tab>
      <b-tab title="Experts">
        <div class="row" v-if="!loading">
          <div class="col-12">
            <button
              @click="addProject"
              style="float: right"
              class="btn btn-success"
            >
              Add Experts
            </button>
            <div id="search">
              <input
                type="searchField"
                v-model="searchFieldExpert"
                placeholder="Search Experts..."
              />
            </div>

            <div>
              <b-table
                :items="filteredListExperts"
                :fields="fieldsProjects"
                responsive="sm"
                ref="selectableTableExpert"
                selectable
                @row-selected="onRowSelected"
              >
                <template #cell(image)="data">
                  <img
                    v-if="data.value"
                    style="width: 100px; height: 100px"
                    :src="data.value"
                  />
                  <img
                    v-else
                    style="width: 100px; height: 100px"
                    src="@/assets/images/error-img.png"
                  />
                </template>
                <!-- <template #cell(action)>
                  <i @click="console.log('clicked')" class="fas fa-trash"></i>
                </template> -->

                <!-- Example scoped slot for select state illustrative purposes -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">
                      <input type="checkbox" checked />
                    </span>
                  </template>
                  <template v-else>
                    <input type="checkbox" />
                  </template>
                </template>
              </b-table>
              <p>
                <b-button
                  size="sm"
                  @click="selectAllRows('selectableTableExpert')"
                  >Select all</b-button
                >

                <b-button
                  size="sm"
                  @click="clearSelected('selectableTableExpert')"
                  >Clear selected</b-button
                >
              </p>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-12"
            style="
              height: 60vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <pulse-loader color="#505d69" :loading="true"></pulse-loader>
          </div>
        </div>
      </b-tab>
      <b-tab title="Projects">
        <div class="row" v-if="!loading">
          <div class="col-12">
            <button
              @click="addProject"
              style="float: right"
              class="btn btn-success"
            >
              Add Projects
            </button>
            <div id="search">
              <input
                type="searchField"
                v-model="searchField"
                placeholder="Search Projects.."
              />
            </div>

            <div>
              <b-table
                :items="filteredList"
                :fields="fieldsProjects"
                responsive="sm"
                ref="selectableTableProject"
                selectable
                @row-selected="onRowSelected"
              >
                <template #cell(image)="data">
                  <img
                    v-if="data.value"
                    style="width: 100px; height: 100px"
                    :src="data.value"
                  />
                  <img
                    v-else
                    style="width: 100px; height: 100px"
                    src="@/assets/images/error-img.png"
                  />
                </template>
                <!-- <template #cell(action)>
                  <i @click="console.log('clicked')" class="fas fa-trash"></i>
                </template> -->

                <!-- Example scoped slot for select state illustrative purposes -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">
                      <input type="checkbox" checked />
                    </span>
                  </template>
                  <template v-else>
                    <input type="checkbox" />
                  </template>
                </template>
              </b-table>
              <p>
                <b-button
                  size="sm"
                  @click="selectAllRows('selectableTableProject')"
                  >Select all</b-button
                >

                <b-button
                  size="sm"
                  @click="clearSelected('selectableTableProject')"
                  >Clear selected</b-button
                >
              </p>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-12"
            style="
              height: 60vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <pulse-loader color="#505d69" :loading="true"></pulse-loader>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { projects } from "@/config/api/projects";
import { sliders } from "@/config/api/sliders";
import { companies } from "@/config/api/companies";
import { users } from "@/config/api/users";

export default {
  data() {
    return {
      selectedProjects: [],
      allProjects: [],
      fields: ["selected", "name", "image", "type"],
      fieldsProjects: ["selected", "name", "image"],
      loading: false,
      searchResult: undefined,
      allProjectsAvailable: [],
      searchField: "",
      searchFieldCompany: "",
      searchFieldExpert: "",
      title: "Sliders",
      items: [
        {
          text: "All Sliders",
          to: { name: "Sliders" },
        },
        {
          text: "Edit Sliders",
          active: true,
        },
      ],
      list: [],
    };
  },
  async created() {
    await this.loadSlider();
  },
  methods: {
    async loadSlider() {
      this.loading = true;
      let api = sliders.model.get;
      let result = await this.generateAPI(api);
      this.searchResult = result.data.sliders.find(
        (item) => item._id === this.$route.query.id
      );

      this.allProjects.splice(0, this.allProjects.length);
      api = projects.model.get;
      result = await this.generateAPI(api);
      this.allProjectsAvailable = result.data.projects.map((item) => {
        return {
          ...item,
          type: "Project",
          name: item.name_en ?? "N/A",
          image: item.cover_image,
        };
      });
      this.allProjects.push(
        ...this.allProjectsAvailable.filter((item) =>
          this.searchResult.project_ids.includes(item._id)
        )
      );
      api = companies.get;
      result = await this.generateAPI(api);
      this.allCompaniesAvailable = result.data.companies.map((item) => {
        return {
          ...item,
          type: "Company",
          name: item.display_name,
          image: item.cover_image,
        };
      });
      this.allProjects.push(
        ...this.allCompaniesAvailable.filter((item) =>
          this.searchResult.company_ids.includes(item._id)
        )
      );

      api = users.getAll;
      result = await this.generateAPI(api);

      this.allUsersAvailable = result.data.users
        .filter((item) => item.is_expert)
        .map((item) => {
          return { ...item, type: "Expert" };
        });
      this.allProjects.push(
        ...this.allUsersAvailable
          .filter((item) =>
            this.searchResult.project_experts.includes(item._id)
          )
          .map((item) => {
            return { ...item, type: "Expert" };
          })
      );
      this.loading = false;
    },
    async removeProject() {
      this.allProjects = this.allProjects.filter(
        (item) => !this.selectedProjects.includes(item._id)
      );
      const api = { ...sliders.model.update };
      let payload = {
        "project_ids[]": this.allProjects
          .filter((item) => item.type === "Project")
          .map((item) => item._id),
        "company_ids[]": this.allProjects
          .filter((item) => item.type === "Company")
          .map((item) => item._id),
        "project_experts[]": this.allProjects
          .filter((item) => item.type === "Expert")
          .map((item) => item._id),
      };
      api.data = payload;
      api.url += this.$route.query.id;
      await this.generateAPI(api);
      this.loadSlider();
    },
    onRowSelected(items) {
      this.selectedProjects = items.map((item) => item._id);
    },
    selectAllRows(type) {
      this.$refs[type].selectAllRows();
    },
    clearSelected(type) {
      this.$refs[type].clearSelected();
    },
    async addProject() {
      const api = { ...sliders.model.update };
      let payload = {
        "project_ids[]": this.allProjects
          .filter((item) => item.type === "Project")
          .map((item) => item._id),
        "company_ids[]": this.allProjects
          .filter((item) => item.type === "Company")
          .map((item) => item._id),
        "project_experts[]": this.allProjects
          .filter((item) => item.type === "Expert")
          .map((item) => item._id),
      };
      for (let i = 0; i < this.selectedProjects.length; i++) {
        if (
          this.allProjectsAvailable.filter(
            (item) => this.selectedProjects[i] === item._id
          ).length > 0
        ) {
          payload["project_ids[]"].push(...[this.selectedProjects[i]]);
        } else if (
          this.allCompaniesAvailable.filter(
            (item) => this.selectedProjects[i] === item._id
          ).length > 0
        ) {
          payload["company_ids[]"].push(...[this.selectedProjects[i]]);
        } else if (
          this.allUsersAvailable.filter(
            (item) => this.selectedProjects[i] === item._id
          ).length > 0
        ) {
          payload["project_experts[]"].push(...[this.selectedProjects[i]]);
        }
      }
      api.data = payload;
      api.url += this.$route.query.id;
      await this.generateAPI(api);
      this.loadSlider();
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  watch: {},
  computed: {
    filteredList: {
      get: function () {
        return this.allProjectsAvailable.filter((post) => {
          return post.name
            .toLowerCase()
            .includes(this.searchField.toLowerCase());
        });
      },
    },
    filteredListCompanies: {
      get: function () {
        return this.allCompaniesAvailable.filter((post) => {
          return post.display_name
            .toLowerCase()
            .includes(this.searchFieldCompany.toLowerCase());
        });
      },
    },
    filteredListExperts: {
      get: function () {
        return this.allUsersAvailable.filter((post) => {
          return post.name
            .toLowerCase()
            .includes(this.searchFieldExpert.toLowerCase());
        });
      },
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.dragged_yes {
  height: 300px;
}
.project-images {
  height: 200px;
  width: 200px;
  object-fit: fill;
}
</style>
