var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"active":"","title":"My Slider"}},[(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-danger",staticStyle:{"float":"right"},on:{"click":_vm.removeProject}},[_vm._v(" Remove Selected ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',[_c('b-table',{ref:"selectableTableSlider",attrs:{"items":_vm.allProjects,"fields":_vm.fields,"responsive":"sm","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [(data.value)?_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":data.value}}):_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("@/assets/images/error-img.png")}})]}},{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"checkbox","checked":""}})])]:[_c('input',{attrs:{"type":"checkbox"}})]]}}],null,false,1397907176)}),_c('p',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.selectAllRows('selectableTableSlider')}}},[_vm._v("Select all")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.clearSelected('selectableTableSlider')}}},[_vm._v("Clear selected")])],1)],1)])])])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"height":"60vh","display":"flex","justify-content":"center","align-items":"center"}},[_c('pulse-loader',{attrs:{"color":"#505d69","loading":true}})],1)])]),_c('b-tab',{attrs:{"title":"Companies"}},[(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-success",staticStyle:{"float":"right"},on:{"click":_vm.addProject}},[_vm._v(" Add Company ")]),_c('div',{attrs:{"id":"search"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFieldCompany),expression:"searchFieldCompany"}],attrs:{"type":"searchField","placeholder":"Search Companies.."},domProps:{"value":(_vm.searchFieldCompany)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchFieldCompany=$event.target.value}}})]),_c('div',[_c('b-table',{ref:"selectableTableCompany",attrs:{"items":_vm.filteredListCompanies,"fields":_vm.fieldsProjects,"responsive":"sm","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [(data.value)?_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":data.value}}):_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("@/assets/images/error-img.png")}})]}},{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"checkbox","checked":""}})])]:[_c('input',{attrs:{"type":"checkbox"}})]]}}],null,false,1397907176)}),_c('p',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.selectAllRows('selectableTableCompany')}}},[_vm._v("Select all")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.clearSelected('selectableTableCompany')}}},[_vm._v("Clear selected")])],1)],1)])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"height":"60vh","display":"flex","justify-content":"center","align-items":"center"}},[_c('pulse-loader',{attrs:{"color":"#505d69","loading":true}})],1)])]),_c('b-tab',{attrs:{"title":"Experts"}},[(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-success",staticStyle:{"float":"right"},on:{"click":_vm.addProject}},[_vm._v(" Add Experts ")]),_c('div',{attrs:{"id":"search"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFieldExpert),expression:"searchFieldExpert"}],attrs:{"type":"searchField","placeholder":"Search Experts..."},domProps:{"value":(_vm.searchFieldExpert)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchFieldExpert=$event.target.value}}})]),_c('div',[_c('b-table',{ref:"selectableTableExpert",attrs:{"items":_vm.filteredListExperts,"fields":_vm.fieldsProjects,"responsive":"sm","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [(data.value)?_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":data.value}}):_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("@/assets/images/error-img.png")}})]}},{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"checkbox","checked":""}})])]:[_c('input',{attrs:{"type":"checkbox"}})]]}}],null,false,1397907176)}),_c('p',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.selectAllRows('selectableTableExpert')}}},[_vm._v("Select all")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.clearSelected('selectableTableExpert')}}},[_vm._v("Clear selected")])],1)],1)])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"height":"60vh","display":"flex","justify-content":"center","align-items":"center"}},[_c('pulse-loader',{attrs:{"color":"#505d69","loading":true}})],1)])]),_c('b-tab',{attrs:{"title":"Projects"}},[(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-success",staticStyle:{"float":"right"},on:{"click":_vm.addProject}},[_vm._v(" Add Projects ")]),_c('div',{attrs:{"id":"search"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchField),expression:"searchField"}],attrs:{"type":"searchField","placeholder":"Search Projects.."},domProps:{"value":(_vm.searchField)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchField=$event.target.value}}})]),_c('div',[_c('b-table',{ref:"selectableTableProject",attrs:{"items":_vm.filteredList,"fields":_vm.fieldsProjects,"responsive":"sm","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [(data.value)?_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":data.value}}):_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("@/assets/images/error-img.png")}})]}},{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"checkbox","checked":""}})])]:[_c('input',{attrs:{"type":"checkbox"}})]]}}],null,false,1397907176)}),_c('p',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.selectAllRows('selectableTableProject')}}},[_vm._v("Select all")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.clearSelected('selectableTableProject')}}},[_vm._v("Clear selected")])],1)],1)])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"height":"60vh","display":"flex","justify-content":"center","align-items":"center"}},[_c('pulse-loader',{attrs:{"color":"#505d69","loading":true}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }